import { Link } from '@dx-ui/osc-link';
import cx from 'classnames';

export type InformationTileProps = {
  children: React.ReactNode;
  ctaType?: 'button' | 'link';
  shouldInlineOnLg?: boolean;
  label?: string;
  url?: string;
  icon?: JSX.Element;
  title: string;
};

export const InformationTile = ({
  children,
  ctaType,
  shouldInlineOnLg,
  label,
  url,
  icon,
  title,
}: InformationTileProps) => (
  <div
    className="bg-bg border-border-alt flex size-full flex-row items-start rounded-lg border p-4"
    data-testid="InformationTile"
    tabIndex={0}
  >
    {icon && (
      <span data-testid="icon" className="text-primary pr-2 pt-1">
        {icon}
      </span>
    )}
    <div
      className={cx('flex flex-col', {
        'justify-between lg:w-full lg:flex-row': shouldInlineOnLg,
      })}
    >
      <div className="mb-2 mt-1">
        <h2 className="heading-lg lg:heading-xl">{title}</h2>
        <div className="pt-2 text-base leading-5 lg:pt-0">{children}</div>
      </div>
      {ctaType && url && (
        <div
          className={cx('pt-4 lg:pt-0', {
            'lg:self-center': shouldInlineOnLg,
          })}
        >
          {ctaType === 'button' && (
            <div className="flex w-full">
              <Link
                role="button"
                label={label}
                url={url}
                className="btn btn-primary lg:btn-xl"
                underline={false}
                isNewWindow
                showNewWindowIcon={false}
              >
                {label}
              </Link>
            </div>
          )}
          {ctaType === 'link' && (
            <div className="flex w-full">
              <Link role="link" adaDescription={label} label={label} url={url} isNewWindow>
                {label}
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  </div>
);
