import type { InformationTileProps } from '../information-tile';
import { InformationTile } from '../information-tile';

type InformationStripProps = {
  tiles: InformationTileProps[];
};

export const InformationStrip = ({ tiles }: InformationStripProps) => {
  return (
    <div className="container" data-testid="InformationStrip">
      {tiles?.length <= 6 && tiles?.length > 0 && (
        <ul className="flex flex-row flex-wrap gap-4 pb-6 pt-4">
          {tiles.map((card) => (
            <li key={card.title} className="w-full min-w-[328px] flex-1 snap-start snap-always">
              <InformationTile
                ctaType={card.ctaType}
                icon={card.icon}
                label={card.label}
                title={card.title}
                url={card.url}
              >
                {card.children}
              </InformationTile>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
